import React, { useState, useEffect } from "react";
import {
  Row,
  Typography,
  Col,
  Form,
  notification,
  Button,
  Input
} from "antd";
import { getAgriculturistLimitsApi, updatedLimitApi } from "../../services/agriculturistApi";
import { CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";
import { convertFloatDotSeperated } from "../../helpers/common";

const { Text } = Typography;

const AgriculturistLimit = () => {
  const [form] = Form.useForm();
  const [agriculturist, setAgriculturist] = useState<any>([]);
  const [agriculturistLimit, setAgriculturistLimit] = useState<any>([]);
  const [financialInstitutionId, setFinancialInstitutionId] = useState<any>('');
  const [inputValue, setInputValue] = useState<any>([]);

  const getAgriculturistLimits  = async () => {
    try {
      
      const response: any = await getAgriculturistLimitsApi();
      if (response) {
        setAgriculturist(response.data.agriculturistInformations);
        setAgriculturistLimit(response.data.agriculturistInformations.limits);
        setFinancialInstitutionId(response.data.agriculturistInformations.financialInstitutionId)
      }
    } catch (error: any) {      
      console.log(error)     
      notification.error({
        message: 'Beklenmedik bir hata oluştu..',
        duration: 3,
      });
    }
  }

  const updateFinanceLimit = async (value :any, financialId:any, id:any) => {  
    const newData = {
      financialInstitutionId: financialId,
      agriculturistId: id,
      limit: Number(value)
    } 
          
    if(value !== '' || value !== undefined ){
      try{
        const response: any = await updatedLimitApi(newData);            
        if(response?.status === 200){
            notification.success({message: 'Limit başarıyla güncellenmiştir.'});  
            window.location.reload();
        }         
      }catch(e:any){
        if(e.response.data.Status === 406 && e.response.data.Type.includes('BuyerLimitExcessException')){
          notification.warning({message: 'Çiftçi Limiti Alıcı Limitinden Fazla Olamaz.'});
        }  
      }     
    }else{
      notification.success({message: 'Güncellenecek limit değerini girmediniz.'});
    }    
  }

  useEffect(() => {
    getAgriculturistLimits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      <Row  className="my-suppliers">
      <Col span={24}  className="supplier-limit">       
      <Row>
        <Text className="limit-title">Limit Bölüştür</Text>
        <Text style={{fontWeight:'600'}}>Toplam limitinizi çiftçiniz arasında bölüştürün.</Text>       
      </Row>
      <Row>
        <Text style={{marginTop:'15px', fontSize:'20px '}}>
            Banka Risk/Limit Durumu 
          </Text>
      </Row>
      <Row className="limit-header-title">
          <Col span={24} className="mobil-t-title">
              <div className="title buyer-definitions-title argo-definitions-title">
                  <div>Finans Kurumu</div>
                  <div>Mevcut Risk</div>
                  <div>Limit</div>
                  <div>Risk/ <br/> Limit %</div>
                  {/* <div>Çiftçi <br/> Adedi</div> */}
              </div>
              <div className="table-li buyer-definitions-li argo-definitions-li" style={{marginLeft:'0'}}>
                  <div>
                        <div>{agriculturist.financialInstitutionName}</div>
                        <div>{convertFloatDotSeperated(agriculturist.buyerRisk)} TL</div>
                        <div>{convertFloatDotSeperated(agriculturist.buyerLimit)} TL</div>
                        <div>{agriculturist.buyerRisk === 0 ? '-' : ((agriculturist.buyerRisk * 100)/agriculturist.buyerLimit)?.toFixed(2)} %</div>
                        {/* <div>{item.supplierCount}</div>                            */}
                    </div>       
                
                {/* <div>
                  <div>TOPLAM</div>
                  <div>{bankRiskTotal && convertFloatDotSeperated(bankRiskTotal)} TL</div>
                  <div>{bankLimitTotal && convertFloatDotSeperated(bankLimitTotal)} TL</div>
                  <div>{bankRiskTotal === 0 ? '-' : (bankRiskTotal && bankLimitTotal && (bankRiskTotal * 100)/bankLimitTotal)?.toFixed(2)} %</div>
                  <div>{supplierCountTotal && supplierCountTotal}</div>
                </div> */}
              </div>
          </Col>
        </Row>
      <Row style={{marginTop:'30px'}}>        
        <Text style={{marginTop:'15px', fontSize:'20px '}}>
        Çiftçi Risk/Limit Durumu ve Güncelleme
        </Text>
        <Text style={{marginTop:'15px', fontStyle:'italic'}}><b>"Toplam Limitinizi "</b>Çiftçileriniz arasında uygun gördüğünüz şekilde bölüştürebilirsiniz. Herhangi bir Çiftçiye vereceğiniz limit en çok <b>“Toplam Limitiniz”</b> kadar olabilir. Ancak tüm Çiftçilerinize toplam kullandırımınız <b>“Toplam Limitiniz”</b>i aşamaz.</Text>
      </Row>         
      {/* <Row style={{marginTop:'20px'}}>
          <Col span={10}>
            <Form
               name="filter-box"
              //  onFinish={onchangeValue}
               autoComplete="off"
               labelCol={{
                 span: 8,
               }}
               size = "large"
               style={{
                 minWidth: 400,
               }}
            >
              <div className='filter-row'>                
                <div>
                  <Form.Item name="title" >
                    <Input 
                      placeholder="Çiftçi Adı veya VKN'sini Seçiniz"
                      className="search-argo"
                     />
                    </Form.Item>
                </div>               
                <div>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"                 
                    >Ara</Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Col>
      </Row> */}
      <div className="limit-header-title-mobil">
        <Row className="limit-header-title">
          <Col span={24}>
              <div className="title title-mobil">
                  <div>Çiftçi Adı</div>
                  <div>VKN No</div>
                  <div>Mevcut Risk</div>
                  <div>Limit</div>
                  <div>Risk/ <br/> Limit %</div>
                  <div>Platforma <br/> Kayıt Durumu</div>
              </div>
          </Col>
        </Row>
        <Row >
          <Col span={24}>
          <div className="table-li table-li-mobil limit-acc-style">                   
                      {
                        agriculturistLimit.map((item:any, i:any) => {
                          return(
                            <div>
                              <div>{item.nameSurname}</div> 
                              <div>{item.taxNumber}</div>
                              <div>{convertFloatDotSeperated(item.risk)} TL</div>
                              <div>
                                  <Form form={form} className="supplier-limit-input" style={{display:'flex'}}>
                                    <Form.Item 
                                      name={`limit${i}` }
                                      >
                                      <Input                                        
                                        placeholder={convertFloatDotSeperated(item.limit)} 
                                          onInput={(e:any) => {
                                            setInputValue((prevArr: any) => {
                                              const result = [...prevArr];
                                              result[i] = e.target.value;
                                              return result;
                                            });
                                          }}
                                        className="no-spin input-width"
                                        style={{borderRadius:'8px'}}
                                        type="text"
                                      />           
                                    </Form.Item>
                                  <Form.Item>
                                     <Button 
                                      className="supplier-limit-btn"
                                      onClick={() => updateFinanceLimit(
                                        inputValue[i], 
                                        financialInstitutionId, 
                                        item.id
                                        ) } 
                                    >
                                      Güncelle
                                    </Button> 
                                  </Form.Item>
                                </Form>                                
                              </div>
                              <div>{item.risk === 0 ? '-' : ((item.risk * 100)/item.limit)?.toFixed(2)} % </div>  
                              <div>
                              {
                                item.isRegistred 
                                ? <CheckCircleOutlined style={{color: '#62b61f', fontSize: 22}} /> 
                                : <CloseCircleOutlined style={{color: '#cc2c2c', fontSize: 22}} />
                              }
                              </div>                         
                            </div>
                          )
                        })
                      }
                    </div>   

          </Col>       
        </Row>
      </div>      
    </Col>
      </Row>
    </main>
   
  );
};

export default AgriculturistLimit;
