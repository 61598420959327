import * as React from "react";
import { createContext, ReactNode, useEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import Login from "../pages/Login";
import ForgetPassword from "../pages/ForgetPassword";
import { fakeAuthProvider, urls } from "./auth";
import Home from "../pages/Home";
import ApprovalReqDetail from "../pages/ApprovalReqDetail";
import ResetPassword from "../pages/ResetPassword";
import CreatePassword from "../pages/CreatePassword";
import BillStatus from "../pages/BillStatus";
import UploadInvoices from "../pages/UploadInvoices";
import DeleteInvoices from "../pages/DeleteInvoices";
import UpdateMaturityInvoice from "../pages/UpdateMaturityInvoice";
import InvoiceAssigned from "../pages/InvoiceAssigned";
import InvoiceDues from "../pages/InvoiceDues";
import SupplierTransactions from "../pages/SupplierTransactions";
import { LocalStorageService } from "../helpers/LocalStorage";
import MySuppliers from "../pages/Suppliers/MySuppliers";
import SupplierLimit from "../pages/Suppliers/SupplierLimit";
import Dashboard from "../pages/Dashboard";
import TwoFactor from "../pages/TwoFactor";
import MyAccount from "../pages/myAccount";
import PasswordUpdate from "../pages/PasswordUpdate";
import AllTransactions from "../pages/AllTransactions";

import BillStatusMustahsil from "../pages/Agriculturists/BillStatusMustahsil";
import InvoiceAssignedMustahsil from "../pages/Agriculturists/InvoiceAssignedMustahsil";
import InvoiceDuesMustahsil from "../pages/Agriculturists/InvoiceDuesMustahsil";
import SupplierTransactionsMustahsil from "../pages/Agriculturists/SupplierTransactionsMustahsil";
import AllTransactionsMustahsil from "../pages/Agriculturists/AllTransactionsMustahsil";
import AgriculturistLimit from "../pages/Agriculturists/AgriculturistLimit";
import MyAgriculturist from "../pages/Agriculturists/MyAgriculturist";
import UploadFames from "../pages/Agriculturists/UploadFames";
import DeleteAgriculturist from "../pages/Agriculturists/DeleteAgriculturist";
import UpdateMaturityMustahsil from "../pages/Agriculturists/UpdateMaturityMustahsil";


const AppRouter = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path={`${urls.passwordCreate}/:passwordToken/:token`}
          element={<CreatePassword />}
        />
        <Route
          path={`${urls.passwordUpdate}/:passwordToken/:token`}
          element={<ResetPassword />}
        />
        <Route
          path={`${urls.passwordReset}/:passwordToken/:token`}
          element={<ResetPassword />}
        />

        <Route path={urls.forgetPassword} element={<ForgetPassword />} />

        <Route
          path={urls.twoFactor}
          element={
            <RequireTwoFactor>
              <TwoFactor />
            </RequireTwoFactor>
          }
        />
        <Route
          path={urls.home}
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        >
          <Route
            path={urls.dashboard}
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />

          <Route
            path={urls.uploadInvoice}
            element={
              <RequireAuth>
                <UploadInvoices />
              </RequireAuth>
            }
          />
          <Route
            path={urls.deleteInvoice}
            element={
              <RequireAuth>
                <DeleteInvoices />
              </RequireAuth>
            }
          />
          <Route
            path={urls.allTransactions}
            element={
              <RequireAuth>
                <AllTransactions />
              </RequireAuth>
            }
          />
          <Route
            path={urls.updateMaturityInvoice}
            element={
              <RequireAuth>
                <UpdateMaturityInvoice />
              </RequireAuth>
            }
          />
          <Route
            path={urls.billStatus}
            element={
              <RequireAuth>
                <BillStatus />
              </RequireAuth>
            }
          />
          <Route
            path={urls.invoiceAssigned}
            element={
              <RequireAuth>
                <InvoiceAssigned />
              </RequireAuth>
            }
          />
          <Route
            path={urls.invoiceDue}
            element={
              <RequireAuth>
                <InvoiceDues />
              </RequireAuth>
            }
          />
          <Route
            path={urls.supplierTransactions}
            element={
              <RequireAuth>
                <SupplierTransactions />
              </RequireAuth>
            }
          />
          <Route
            path={urls.mySupplier}
            element={
              <RequireAuth>
                <MySuppliers />
              </RequireAuth>
            }
          />
          <Route
            path={urls.supplierLimits}
            element={
              <RequireAuth>
                <SupplierLimit />
              </RequireAuth>
            }
          />
          <Route
            path="/details/:id"
            element={
              <RequireAuth>
                <ApprovalReqDetail />
              </RequireAuth>
            }
          />
          <Route
            path={urls.myAccount}
            element={
              <RequireAuth>
                <MyAccount />
              </RequireAuth>
            }
          />
          <Route
            path={urls.passwordChange}
            element={
              <RequireAuth>
                <PasswordUpdate />
              </RequireAuth>
            }
          />

          <Route
            path={urls.allMustahsiller}
            element={
              <RequireAuth>
                <BillStatusMustahsil />
              </RequireAuth>
            }
          />

          <Route
            path={urls.invoiceAssignedMustahsil}
            element={
              <RequireAuth>
                <InvoiceAssignedMustahsil />
              </RequireAuth>
            }
          />

          <Route
            path={urls.invoiceDuesMustahsil}
            element={
              <RequireAuth>
                <InvoiceDuesMustahsil />
              </RequireAuth>
            }
          />

          <Route
            path={urls.supplierTransactionsMustahsil}
            element={
              <RequireAuth>
                <SupplierTransactionsMustahsil />
              </RequireAuth>
            }
          />
            <Route
            path={urls.myAgriculturist}
            element={
              <RequireAuth>
                <MyAgriculturist />
              </RequireAuth>
            }
          />
          
          <Route
          path={urls.allTransactionsMustahsil}
          element={
            <RequireAuth>
              <AllTransactionsMustahsil />
            </RequireAuth>
          }
        />  
        
        <Route
        path={urls.agriculturistLimit}
        element={
          <RequireAuth>
            <AgriculturistLimit />
          </RequireAuth>
        }
      /> 
      
      <Route
      path={urls.myAgriculturist}
      element={
        <RequireAuth>
          <MyAgriculturist />
        </RequireAuth>
      }
     /> 
     <Route
      path={urls.uploadMustahsil}
      element={
        <RequireAuth>
          <UploadFames />
        </RequireAuth>
      }
      />
      <Route
      path={urls.deleteAgriculturist}
      element={
        <RequireAuth>
          <DeleteAgriculturist />
        </RequireAuth>
      }
      />
       <Route
        path={urls.updateMaturityMustahsil}
        element={
          <RequireAuth>
            <UpdateMaturityMustahsil />
          </RequireAuth>
        }
        />

        </Route>
      </Routes>
    </AuthProvider>
  );
};

interface AuthContextType {
  token: any;
  signin: (token: string, callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
}

const AuthContext = createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: ReactNode }) {
  const [token, setToken] = useState<any>(null);


  useEffect(() => {
    const token = LocalStorageService.getAuthToken();
    token && LocalStorageService.setAuthToken(token);
  }, []);

  const signin = (newToken: string, callback: VoidFunction) => {
    return fakeAuthProvider.signin(() => {
      setToken(newToken);
      callback();
    });
  };

  const signout = (callback: VoidFunction) => {
    return fakeAuthProvider.signout(() => {
      setToken(null);
      callback();
    });
  };

  const value = { token, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}

function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();
  const token = LocalStorageService.getAuthToken();
  const isLogout = LocalStorageService.getIsLogout();
  
  if (!auth.token && !token && isLogout) {
    LocalStorageService.removeIsLogout()
    return <Navigate to={urls.login} state={{ from: location }} replace />;
  }
  if (token && location.pathname === "/" && !isLogout) {
    return (
      <Navigate to={urls.dashboard} state={{ from: location }} replace />
    );
  } 

  return children;
}


function RequireTwoFactor({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();
  const token = LocalStorageService.getAuthToken();
  const { email } = useSelector((state: any) => state.user);
  const isLogout = LocalStorageService.getIsLogout();

  if (!auth.token && !token && !email && isLogout) {
    LocalStorageService.removeIsLogout();
    return <Navigate to={urls.login} state={{ from: location }} replace />;
  }
  if (token && !email && !isLogout) {
    return <Navigate to={urls.dashboard} state={{ from: location }} replace />;
  }
  if (token && location.pathname === "/" && !isLogout) {
    return (
      <Navigate to={urls.dashboard} state={{ from: location }} replace />
    );
  }

  return children;
}


export default AppRouter;