import { ServiceHelper } from ".";

const getAgriculturistLimitsApi = async (): Promise<Object> => {
  return await ServiceHelper.get(`agriculturist/limits`,
    {}
  );
};

const updatedLimitApi = async (newData: any): Promise<Object> => {
  return await ServiceHelper.patch(`agriculturist/limit`, newData, {} );
};


export {
  getAgriculturistLimitsApi,
  updatedLimitApi
};