import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Input,
  notification,
  Layout,
  Button,
} from "antd";
import { resetPasswordApi } from "../services/userAuth";
import { Content } from "antd/lib/layout/layout";
import logo from "../assets/logo.png";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import {validatePassword} from '../utils/validators';


const ResetPassword: React.VFC = () => {
  let navigate = useNavigate();
  const [isLoding, setIsLoading] = useState(false);

  const location = useLocation();
  const token = location.pathname.split('/');
  const buyerUserId = token[3];
  const passwordToken = (token[2] === 'forget' || token[2] === 'update') && token[4];


  const onResetPassword = async (args: any) => {
    try {
      setIsLoading(true);
      if(args.password && args.confirmPassword && args.password === args.confirmPassword ) {
        let data: any = {
          passwordToken: passwordToken || '',
          newPassword: args.password,
        }
        const response: any = await resetPasswordApi(data, buyerUserId );
       
        if (response?.status === 200) {
          setIsLoading(false);
          navigate("/login");
        }
      } else {
        setIsLoading(false);
        notification.error({
          message: 'Şifre ve şifreyi onayla eşleşmiyor',
          duration: 3,
        });
      }
    } catch (error: any) {
      console.log(error)
      setIsLoading(false);
      if(error.response.data.Status === 400 && error.response.data.Messages ){
        const length = error.response.data.Messages.length - 1;
        let x = 0;
        for( x=0; x < length + 1 ; x+=1){
            notification.warning({
                message: ` ${error.response.data.Messages[x]}`  
            });
        } 
      }
      if(error.response.data.Status === 400 && error.response.data.Type.includes('PasswordTokenNotValidException') ){
        notification.warning({
            message: `Şifre yenileme bağlantısının süresi doldu, lütfen yeniden Şifremi Unuttum seçeneği ile ilerleyin.`,
        });
      }
      if(error.response.data.Status === 409 && error.response.data.Type.includes('LastThreePasswordMatchedException') ){
        notification.warning({
            message: `Yeni parola son üç parola ile aynı olamaz.`,
        });
      }
      error.response.data.title && notification.error({
        message: 'Beklenmedik bir hata oluştu.',
        duration: 3,
      });
    }
  };



  return (
    <Layout className="layout">
      <Row className="header-login"> 
          <div>
              <img height="40px" className="mb-big" src={logo} alt="Depar.io" />
          </div>
      </Row>
      <Content className="content">
        <Row justify="center">
          <Col xs={24} md={16} lg={8}>
            <span className="loginSpan">Şifre Güncelle</span>
            <p>Lütfen belirlemiş olduğunuz yeni şifrenizi onaylamak için iki defa giriniz.</p>
            <Form name="login" onFinish={onResetPassword}>
              <Form.Item
                style={{ margin: "0px" }}
                name="password"
                rules={[
                  {
                    required: true,
                    validator: (_, value) => validatePassword(value)
                  },
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder="Yeni Şifre"
                  iconRender={(visible: any) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
              <Form.Item
                style={{ margin: "0px" }}
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    validator: (_, value) => validatePassword(value)
                  },
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder="Yeni Şifre Tekrarla"
                  iconRender={(visible: any) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
            
              <Form.Item style={{marginTop: 30}}>
                <Button
                  className="login-btn"
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={isLoding}
                  block
                >
                  Şifremi Güncelle
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
     
      </Content>
    </Layout>
  );
};

export default ResetPassword;
