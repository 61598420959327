import React, { useState } from "react";
import { Button, Col, Form, Input, Layout, notification, Row } from 'antd';
import logo from "../assets/logo.png";
import { useDispatch } from "react-redux";
import {setUserData, setLoggedIn} from "../reducers/userSlice"
import { loginApi, expiredPasswordApi } from "../services/userAuth";
import { useNavigate } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { LocalStorageService } from "../helpers/LocalStorage";

const Content = Layout.Content;

const Login = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    
    const onLogin = async (args: any) => {
        try {
            setIsLoading(true);
            const response: any = await loginApi(args);
            if (response?.data.token) {  
                dispatch(setLoggedIn(response?.data?.token));
                LocalStorageService.setAuthToken(response?.data?.token);
                LocalStorageService.setName(response?.data?.nameSurname); 
                LocalStorageService.setBuyerType(response?.data?.buyerType); 
                LocalStorageService.setIsLogout(false)
                setIsLoading(false);
                navigate("/anasayfa");
            }else{
                setIsLoading(false);
                dispatch(setUserData(response.data));                           
                navigate("/twoFactor");
            }
        } catch (e: any) {
            setIsLoading(false);
            if(e.response.data.Status === 404 && e.response.data.Type.includes('UserNotFoundException')){
                notification.error({
                    message: `Kullanıcı adınız ya da şifreniz hatalıdır.`,
                });
            }
            if(e.response.data.Status === 401){
                notification.error({
                    message: `Kullanıcı adınız ya da şifreniz hatalıdır.`,
                });
            }
            if(e.response.data.Status=== 403){ 
                notification.warning({
                    message: `Devam edebilmek için şifrenizi değiştirmeniz gerekmektedir. Kayıtlı mailinizdeki bağlantıyı takip ederek şifrenizi değiştirebilirsiniz.`,
                });              
                const data = {email: args.email}
                await expiredPasswordApi(data);
            }
            if(e.response.data.Status === 401 && e.response.data.Type.includes('UserHasLockedException')){
                notification.error({
                    message: `Birden fazla başarısız giriş denemesi gerçekleştirdiniz. Lütfen 1 dakika sonra tekrar deneyiniz.`,
                });
            }
           
        }
    }

    return (
        <Layout className="layout">
            <Row className="header-login"> 
                <Col xs={24} md={12} lg={7} style={{textAlign:'center'}}>
                    <img height="40px" className="mb-big" src={logo} alt="Depar.io" />
                </Col>
            </Row>
            <Content className="content">
                <Row justify="center">
                    <Col xs={24} md={12} lg={7}>
                        <p className="loginSpan">Depar.io hesabıyla giriş yapın!</p>
                        <Form name="login" onFinish={onLogin} >
                            <Form.Item
                                className="email-input"
                                name="email"
                                rules={[
                                    { type: 'email', message: 'Lütfen geçerli bir e-posta adresi giriniz!' },
                                    {
                                        required: true,
                                        message: 'Lütfen email giriniz!',
                                    },
                                ]}
                            >
                                <Input placeholder="E-Posta Adresi" />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Lütfen şifrenizi giriniz!',
                                    },
                                ]}
                            >
                                <Input.Password 
                                    type={"password"} 
                                    placeholder="Şifre"       
                                    iconRender={(visible: any) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} 
                                />
                            </Form.Item>


                            <Form.Item className="m-0">
                                <Button
                                    className="login-btn"
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    loading={isLoading}
                                    block
                                >
                                    Giriş Yap
                                </Button>
                            </Form.Item>

                            <div className="forgotPassword" onClick={()=> navigate("/forgetpassword")}
                            style={{cursor:'pointer'}} >
                                Şifremi Unuttum
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}

export default Login; 