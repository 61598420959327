import React, { useState } from "react";
import { Button,  Popover,  Space, Typography, Drawer, Dropdown ,Divider} from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoggedIn} from "../reducers/userSlice";
import { logoutApi } from "../services/userAuth";
import { urls } from "../router/auth";
import Logo from "../assets/logo.png";
import Icon from "../assets/icon.svg";
import { LocalStorageService } from "../helpers/LocalStorage";
import {  UserOutlined } from "@ant-design/icons";
import MobilMenu from "./MobilMenu";
import type { MenuProps } from 'antd';

const { Text } = Typography;

const AccountMenu = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const onClickLogout = async () => {
    LocalStorageService.setIsLogout(true);
    try{
        await logoutApi();
        navigate("/login");
        LocalStorageService.removeAuthName();
        LocalStorageService.removeBuyerType();
        dispatch(setLoggedIn(''));
        LocalStorageService.removeAuthToken();
        LocalStorageService.removeIsLogout();
     }catch(e:any){
       console.log(e)
     }
  };

  return (
      <>
          <Link to={urls.myAccount} style={{padding:'20px'}}>
              <Text className="m-0" style={{fontWeight:'600'}} >
                Hesap Bilgilerim 
              </Text>   
          </Link>

          <Divider className="m" style={{margin:'10px 0'}} />

          <Link to={urls.passwordChange} style={{padding:'20px'}}>
              <Text className="m-0" style={{fontWeight:'600'}} >
                Şifre Değiştir
              </Text>   
          </Link>

          <Divider className="m" style={{margin:'10px 0'}} />

          <Link to="">
              <div onClick={onClickLogout}>
                  <Text className="m-0" style={{ color: "red" }}>
                      Çıkış
                  </Text>
              </div>
          </Link>
      </>
  );
};

const Header = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const path = location.pathname;
  const dispatch = useDispatch();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [navShow, setNavShow] = useState(false);
  const userName = LocalStorageService.getName()?.split(" ")
  const userLetters = userName?.length && userName[0].charAt(0).toUpperCase()  + userName[userName.length - 1].charAt(0).toUpperCase();
  const userInfo = LocalStorageService.getName();   
  const userBuyerType =  LocalStorageService.getBuyerType() === 'TEDARIKCI_ALICISI';

  const fauraIslemleleri: MenuProps['items'] = [
   
    {
      key: '1',
      label: (
        <Link to={urls.uploadInvoice }>
          Fatura Yükleme
        </Link>     
      ),
    },
    {
      key: '2',
      label: (
        <Link to={urls.deleteInvoice }>
         Fatura Silme
      </Link> 
      ),
    },
    {
      key: '3',
      label: (
        <Link to={urls.updateMaturityInvoice }>
         Fatura Vadesi Güncelleme
      </Link> 
      ),
    }
  ];
  const tedarikciIslemleleri: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Link to={urls.mySupplier }>
          Tedarikçi Ekle/Sil
        </Link>     
      ),
    },
    {
      key: '2',
      label: (
        <Link to={urls.supplierLimits }>
         Tedarikçi Limitleri
      </Link> 
      ),
    },
  ];

  const raporlar: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Link to={urls.billStatus }>
          Tüm Faturalar
        </Link>     
      ),
    },
    {
      key: '2',
      label: (
        <Link to={urls.invoiceAssigned }>
         Temlik Edilenler
      </Link> 
      ),
    },
    {
      key: '3',
      label: (
        <Link to={urls.invoiceDue }>
         Vadesi Yaklaşanlar
      </Link> 
      ),
    },
    {
      key: '4',
      label: (
        <Link to={urls.supplierTransactions }>
         Tedarikçi Hareketleri İzleme
      </Link> 
      ),
    },
    {
      key: '5',
      label: (
        <Link to={urls.allTransactions }>
          Fatura Hareketleri İzleme
        </Link>     
      ),
    },
   
  ];

  const emustahsilIslemleleri: MenuProps['items'] = [
   
    {
      key: '1',
      label: (
        <Link to={urls.uploadMustahsil }>
          E-Müstahsil Yükleme
        </Link>     
      ),
    },
    {
      key: '2',
      label: (
        <Link to={urls.deleteAgriculturist }>
          E-Müstahsil Silme
      </Link> 
      ),
    },
    {
      key: '3',
      label: (
        <Link to={urls.updateMaturityMustahsil }>
         E-Müstahsil Vadesi Güncelleme
      </Link> 
      ),
    }
  ];
  const ciftciIslemleleri: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Link to={urls.myAgriculturist }>
          Çiftçi Ekle/Sil
        </Link>     
      ),
    },
    {
      key: '2',
      label: (
        <Link to={urls.agriculturistLimit }>
         Çiftçi Limitleri
      </Link> 
      ),
    },
  ];

  const raporlarCiftci: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Link to={urls.allMustahsiller }>
          Tüm E-Müstahsiller
        </Link>     
      ),
    },
    {
      key: '2',
      label: (
        <Link to={urls.invoiceAssignedMustahsil }>
         Temlik Edilenler
      </Link> 
      ),
    },
    {
      key: '3',
      label: (
        <Link to={urls.invoiceDuesMustahsil }>
         Vadesi Yaklaşanlar
      </Link> 
      ),
    },
    {
      key: '4',
      label: (
        <Link to={urls.supplierTransactionsMustahsil }>
         Çiftçi Hareketleri İzleme
      </Link> 
      ),
    },
    {
      key: '5',
      label: (
        <Link to={urls.allTransactionsMustahsil }>
          E-Müstahsiller Hareketleri İzleme
        </Link>     
      ),
    },
   
  ];

  const showDrawer = () => {
    setNavShow(true);
  };
  const onClose = () => {
    setNavShow(false);
  };
  const onClickLogout = async () => {
   LocalStorageService.setIsLogout(true);
    try{
        await logoutApi();
        navigate("/login");
        LocalStorageService.removeAuthName();
        LocalStorageService.removeBuyerType();
        dispatch(setLoggedIn(''));
        LocalStorageService.removeAuthToken();
        LocalStorageService.removeIsLogout();
     }catch(e:any){
       console.log(e)
     }
  };

  return (
   <header>
    <div className="logo">
      <Link to={urls.dashboard}>
        <img className="bigLogo" src={Logo} alt="Depar.io Logo" />
      </Link>
    </div>
    {
      userBuyerType  ? 
      (
        <div className="menu">
          <nav>
            <Space>
            <Dropdown menu={{ items:fauraIslemleleri }} placement="bottomLeft" arrow>
              <Button 
                className={`header-btn temlik-fatura 
                  ${path === urls.uploadInvoice ? 'active-line' 
                  :  path === urls.deleteInvoice ? 'active-line' 
                  :  path === urls.updateMaturityInvoice ? 'active-line' : ''
                  }`} 
              >
                <span>Fatura İşlemleri</span>
              </Button> 
            </Dropdown>
            <Dropdown menu={{ items:tedarikciIslemleleri }} placement="bottomLeft" arrow>
              <Button 
                className={`header-btn temlik-fatura 
                  ${path === urls.mySupplier ? 'active-line' 
                  :  path === urls.supplierLimits ? 'active-line'  : ''
                  }`} 
              >
                <span>Tedarikçi İşlemleri</span>
              </Button> 
            </Dropdown>
            <Dropdown menu={{ items:raporlar }} placement="bottomLeft" arrow>
              <Button 
                className={`header-btn temlik-fatura 
                  ${path === urls.billStatus ? 'active-line' 
                  : path === urls.invoiceAssigned ? 'active-line' 
                  :  path === urls.allTransactions ? 'active-line' 
                  :  path === urls.invoiceDue ? 'active-line' : ''
                  }`} 
              >
                <span>Raporlar</span>
              </Button> 
            </Dropdown>
            </Space>
          </nav>
          <div className={`menu-toggle ${navShow ? 'open': ''}`}  onClick={showDrawer} >
          <div className="hamburger"></div>
        </div>
          <Drawer
          title={
            <Link to={urls.uploadInvoice}>
            <img className="bigLogo" src={Logo} alt="Depar.io Logo" />
            </Link>
            }
            placement="left"
            width={350}
            onClose={onClose}
            open={navShow}
            >
            <div className="mobil-userName">
              <div className="left">
                <UserOutlined />
                <p>{userInfo}</p>
              </div>
              <div style={{textAlign:'right'}}>
                <Link to="">
                    <div onClick={onClickLogout}>
                        <Text className="m-0" style={{ color: "red" }}>
                            Çıkış 
                        </Text>
                    </div>
                </Link>
              </div>
            </div>
            <div className="mobil-menu">
              <MobilMenu/>
            </div>
          </Drawer>
        </div>
      ):
      (
        <div className="menu">
          <nav>
            <Space>
            <Dropdown menu={{ items:emustahsilIslemleleri }} placement="bottomLeft" arrow>
              <Button 
                className={`header-btn temlik-fatura 
                  ${path === urls.uploadInvoice ? 'active-line' 
                  :  path === urls.deleteInvoice ? 'active-line' 
                  :  path === urls.updateMaturityInvoice ? 'active-line' : ''
                  }`} 
              >
                <span>E-Müstahsil İşlemleri</span>
              </Button> 
            </Dropdown>
            <Dropdown menu={{ items:ciftciIslemleleri }} placement="bottomLeft" arrow>
              <Button 
                className={`header-btn temlik-fatura 
                  ${path === urls.mySupplier ? 'active-line' 
                  :  path === urls.supplierLimits ? 'active-line'  : ''
                  }`} 
              >
                <span>Çiftçi İşlemleri</span>
              </Button> 
            </Dropdown>
            <Dropdown menu={{ items:raporlarCiftci }} placement="bottomLeft" arrow>
              <Button 
                className={`header-btn temlik-fatura 
                  ${path === urls.billStatus ? 'active-line' 
                  : path === urls.invoiceAssigned ? 'active-line' 
                  :  path === urls.allTransactions ? 'active-line' 
                  :  path === urls.invoiceDue ? 'active-line' : ''
                  }`} 
              >
                <span>Raporlar</span>
              </Button> 
            </Dropdown>
            </Space>
          </nav>
          <div className={`menu-toggle ${navShow ? 'open': ''}`}  onClick={showDrawer} >
          <div className="hamburger"></div>
        </div>
          <Drawer
          title={
            <Link to={urls.uploadInvoice}>
            <img className="bigLogo" src={Logo} alt="Depar.io Logo" />
            </Link>
            }
            placement="left"
            width={350}
            onClose={onClose}
            open={navShow}
            >
            <div className="mobil-userName">
              <div className="left">
                <UserOutlined />
                <p>{userInfo}</p>
              </div>
              <div style={{textAlign:'right'}}>
                <Link to="">
                    <div onClick={onClickLogout}>
                        <Text className="m-0" style={{ color: "red" }}>
                            Çıkış 
                        </Text>
                    </div>
                </Link>
              </div>
            </div>
            <div className="mobil-menu">
              <MobilMenu/>
            </div>
          </Drawer>
        </div>
      )
    }
   
    <div className="custom-menu">
      <Popover
          style={{minWidth:'200px'}}
            className="cursor-pointer"
            content={() => <AccountMenu  />}
            trigger="click"
            placement="bottomRight"
            onOpenChange={setIsDropdownVisible}
            open={isDropdownVisible}>
            <div style={{ display:'flex', alignItems:'center', gap:'10px'}}> 
              <div className="username-box">
                {userLetters} 
              </div>
              <div className="my-account-box">
              Hesabım
              </div> 
              <img src={Icon} className="my-account-icon" alt="icon"/>
            </div>
        </Popover>
    </div>
   </header>
  );
}

export default Header;
